<template>
  <div
    class="color-picker-wrapper"
    :style="{ background: color }"
    v-if="colorType === 'square'"
  >
    <!-- <button class="color-btn" :id="'btn-' + name" ref="button">
-->
    <!-- Add hex code -->
    <!-- <div class="color-container">
        {{ color }}
      </div> -->

    <verte picker="square" model="hex" :value="color" @input="changeColor">
      {{ display }}
    </verte>

    <!-- </button> -->
  </div>
</template>

<script>
// import Picker from "vanilla-picker";
import Verte from "verte";
import "verte/dist/verte.css";

export default {
  name: "color-picker",
  components: {
    Verte
  },
  props: {
    name: String,
    display: String,
    color: {
      type: String
    },
    colorType: { type: String, default: "square" }
  },
  mounted() {
    // const button = this.$refs.button;
    // const picker = new Picker({
    //   parent: button,
    //   color: this.color || "#222222",
    //   onOpen: () =>
    //     (button.querySelector(".picker_wrapper").style.zIndex = 100),
    // });
    // const self = this;
    // picker.onChange = function (color) {
    //   self.$emit("change", color.hex);
    // };
  },
  methods: {
    changeColor(color) {
      this.$emit("change", color);
    }
  }
};
</script>

<style lang="less">
.color-picker-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin-top: 9px;
  border-radius: 10px;

  div.verte {
    width: 100%;
    align-items: center;

    button.verte__guide {
      color: white !important;
      mix-blend-mode: difference;
      text-align: left;
      mix-blend-mode: difference;
      border-radius: inherit;
      padding: 8px 12px;

      font-size: 1em;
      font-weight: 500;

      width: 100%;
      height: 100%;
    }
  }
  button {
    border: none;
    // border-radius: 5px;
  }

  .color-btn {
    display: flex;
    align-items: center;
    color: #fff;
    mix-blend-mode: difference;

    border-radius: inherit;
    background: transparent;
    padding: 8px 12px;
    position: relative;
    outline: none;
    font-size: 1em;
    font-weight: 500;
    cursor: pointer;

    &:hover::before {
      opacity: 1;
    }

    .color-container {
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }
  }
}
</style>